import Header from '../../components/Header'
import HeaderImage from '../../images/header_bg_1.jfif'
import StoryImage from '../../images/about1.jpg'
import VisionImage from '../../images/about2.jpg'
import MissionImage from '../../images/about3.jpeg'
import { AiOutlineMail } from 'react-icons/ai'
import { BsTelephone } from 'react-icons/bs'
import './about.css'



const About = () => {
  return (
    <>
      <Header title="A Propos de moi" image={HeaderImage}>
        Je décris sur cette page,mon parcours, j'apporte des éléments sur mon lieu de travail et je vous explique mon travail
      </Header>

      <section className="about__story">
        <div className="container about__story-container">
          <div className="about__section-image">
            <img src={StoryImage} alt="Our Story Image" />
          </div>
          <div className="about__section-content">
            <h1>Mon Parcours</h1>
            <p>
              J'ai toujours été passionné de sport depuis ma plus tendre enfance. J'ai pratiqué plusieurs sports comme le foot, du judo, karaté et la musculation. Ce qui m'a permis de m'orienter dans un métier d'éducateur sportif.
            </p>
            <p>
              En 2015, j'ai obtenu un BPJEPS APT (Activité Physique pour Tous) au Greta Val de France.
              Cest alors que j'ai eu un premier aperçu du métier d'éducateur sportif.

            </p>
            <p> Cependant, recevant plusieurs demandes concernant du coaching personnalisé et
              n'ayant la possibilité de l'effectuer avec ce BPJEPS, j'ai décidé d'intégrer
              le prestigieux centre d'apprentissage du CREPS à Chatenay Malabry où j'ai obtenu un nouveau BEPJEPS AF (Activité de la forme) avec une bi-qualification "Cours collectifs et Halterophilie & Musculation"</p>
            <p>
              Depuis 2018, j'ai intégré le réseau Basic-Fit en tant que Personal Trainer.
            </p>
          </div>
        </div>
      </section>

      <section className="about__Vision">
        <div className="container about__Vision-container">
          <div className="about__section-content">
            <h1> Mon approche </h1>
            <p> Premier contact par mail ou par téléphone pour vous aider à définir un programme d'entrainement sportif selon votre profil et vos objectifs.
              Puis rendez-vous à mon lieu de travail pour effectuer un bilan forme pour savoir où vous en êtes physiquement. </p>

            <p> <b>Adresse Salle Basic-Fit où je suis basé :</b> </p>
            <p>

              Salle de sport Basic-Fit Netter situé au 17 avenu du docteur Arnold Netter - 75012 PARIS
            </p>
            <p> <b>Contact :</b> </p>
            <div className='footer__contact  '>
              <AiOutlineMail className='footer-contact-icon about' />
              <p>massaoud.baba@hotmail.fr</p>
            </div>
            <div className='footer__contact'>
              <BsTelephone className='footer-contact-icon about' />
              <p>+33 6 95 39 32 18</p>
            </div>
            <p>

            </p>
          </div>
          <div className="about__section-image">
            <img src={VisionImage} alt="Our Vision Image" />
          </div>
        </div>
      </section>
      <section className="about__mission">
        <div className="container about__mission-container">
          <div className="about__section-image">
            <img src={MissionImage} alt="Our Mission Image" />
          </div>
          <div className="about__section-content">
            <h1> En pratique </h1>
            <p>
              Le coaching avec moi te permets de te muscler et t’affiner sur 12 semaines avec 3 entrainements par semaine. </p>
              <p>  

              Il s'agit d'une Méthode personanilisée pour atteindre ses objectifs
              </p>
              <p> A faire en salle avec machine ou chez soi en poids de corps </p>

           

          </div>
        </div>
      </section>
    </>
  )
}

export default About