import Header from '../../components/Header'
import HeaderImage from '../../images/header_bg_2.jpg'
// import {MdEmail} from 'react-icons/md'
// import {BsMessenger} from 'react-icons/bs'
// import {IoLogoWhatsapp} from 'react-icons/io'
import {FaHandshake} from 'react-icons/fa'
import {FaRegHandshake} from 'react-icons/fa'
import {MdOutlineMailOutline} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import { AiFillInstagram } from 'react-icons/ai'

import Card from '../../UI/Card'
import './contact.css'

import React from 'react';
import { useForm, ValidationError } from '@formspree/react';



const Contact = () => {


  const [state, handleSubmit] = useForm("xvonrlqo");
  if (state.succeeded) {
      return <section className="empty__page">
       <div><FaHandshake className='contact__option-icon merci'/> <FaRegHandshake className='contact__option-icon merci'/> </div>
      <h1>  Merci ! Votre message a bien été envoyé.  </h1>
       
     
      
     </section>
  }

  return (
    <>
    <Header title="Mettons-nous en contact" image={HeaderImage}>
    Une question ? Une suggestion ? Une remarque ? c'est simple. Envoyez-moi votre message.
    </Header>
    <section className="contact">
      <div className="container contact__container">
      <div className="contact__options">
          <article className="contact__option">
          <Card  className='ccontact__option-card'>
          <MdOutlineMailOutline className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>massaoud.baba@hotmail.fr</h5>
            <a  className='btn btn-primary' href="mailto:massaoud.baba@hotmail.fr" target="_blank" rel="noreferrer noopener">Envoyez-moi un message</a>
            </Card>
          </article>
          <article className="contact__option">
          <Card  className='ccontact__option-card'>
          <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>Facebook</h5>
            <a className='btn btn-primary' href="https://www.facebook.com/Escroo.Lescroc" target="_blank" rel="noreferrer noopener">Envoyez-moi un message</a>
            </Card>
          </article>
          <article className="contact__option">
          <Card  className='ccontact__option-card'>
          <AiFillInstagram className='contact__option-icon'/>
            <h4>Instagram</h4>
            <h5>Instagram</h5>
            <a className='btn btn-primary' href="https://www.instagram.com/b.mass_/" target="_blank" rel="noreferrer noopener">Envoyez-moi un message</a>
          </Card>
          </article>
        </div>
        {/* END OF CONTACT OPTION */}
      <form onSubmit={handleSubmit}>
        
        <input type="text" id="last-name" name="last-name" placeholder='Votre Nom' required/>
        <ValidationError prefix="Nom" field="email" errors={state.errors}/>
        <input type="text" id="first-name" name="first-name" placeholder='Votre Prénom' required/>
        <ValidationError prefix="Prenom" field="email" errors={state.errors}/>
        <input type="email" id="email" name="email" placeholder='Votre Email' required/>
        <ValidationError prefix="Email" field="email" errors={state.errors}/>
        <input type="text" id="phone" name="phone" placeholder='Votre N° de téléphone' required/>
        <ValidationError prefix="Telephone" field="email" errors={state.errors}/>
        <input type="text"  id="subject" name="subject" placeholder='Objet' required/>
        <ValidationError prefix="Objet" field="email" errors={state.errors}/>
        <textarea id="message" name="message"  rows="15" placeholder='Votre message' required></textarea>
        <ValidationError prefix="Message" field="email" errors={state.errors}/>
        
         
        
        {/* <button type='submit' className='btn btn-primary' disabled={state.submitting}>Envoyez-moi un message</button> */}
      </form>
      
        {/* <div className="contact__wrapper">
          <a href="mailto:support@egattor.com" target="_blank" rel="noreferrer noopener"><MdEmail/></a>
          <a href="http://m.me/ernest_achiever" target="_blank" rel="noreferrer noopener"><BsMessenger/></a>
          <a href="https://wa.me/+123456789" target="_blank" rel="noreferrer noopener"><IoLogoWhatsapp/></a>
        </div> */}
      </div>
    </section>
   
    </>
  )
}

export default Contact