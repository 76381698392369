import Header from '../../components/Header'
import HeaderImage from '../../images/header_bg_4.jfif'
import Card from '../../UI/Card'
// import {plans} from '../../data'
import './plans.css'

const Plans = () => {
  return (
    <>
    <Header title="Choix tarifaires" image={HeaderImage}>
   Que vous souhaitez des séances individuelles ou de groupe, plusieurs choix tarifaires s'offrent à vous en fonction de votre budget, vos disponibilités, vos objectifs et votre engagement.
    </Header>
    <section className="plans">
      <div className="container plans__container">


        <article>
        <Card  className='plan'>
          <h3>Séances avec abonnement</h3>
          <table>
            <thead>
              <tr>
                <th>Abonnement</th>
                <th>3 mois</th>
                <th>6 mois</th>
                <th>12 mois</th>          
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2 séances de 30 min/semaine</td>
                <td>60 €</td>
                <td> 56 €</td>
                <td>50 €</td>
              </tr>
              <tr>
                <td>1 séance individuel d'1 heure</td>
                <td>55 €</td>
                <td> 50 €</td>
                <td>45 €</td>
              </tr>
              <tr>
                <td>séance duo 1 heure</td>
                <td>40 € /pers</td>
                <td> 35 € /pers</td>
                <td>30 € /pers</td>
              </tr>

            </tbody>
          </table>
          <button className='btn lg'><a href="mailto:sbabatoherou@gmail.com" >Contactez-moi</a></button>
          </Card>
        </article>

        <article>
        <Card  className='plan'>
          <h3>Séances sans abonnement</h3>
          <table>
            <thead>
              <tr>
                <th>Séances</th>
                <th>Tarifs</th>         
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1 heure pour une personne</td>
                <td>65 €/pers</td>
              </tr>
              <tr>
                <td>1 heure pour deux personnes</td>
                <td>50 €/pers</td>
              </tr>
              <tr>
                <td>1 heure pour trois personnes</td>
                <td> 40 €/pers</td>
              </tr>
              <tr>
                <td>1 heure pour quatre personnes</td>
                <td>30 €/pers</td>
              </tr>
              <tr>
                <td>1 heure pour 5 personnes et plus</td>
                <td>25 €/pers</td>
              </tr>
            </tbody>
          </table>
          <button className='btn lg'><a href="mailto:sbabatoherou@gmail.com" >Contactez-moi</a></button>
          </Card>
        </article>


        <article>
        <Card  className='plan'>
          <h3>Programmes et Planification</h3>
          <table>
            <thead>
              <tr>
                <th>séance + programme + planification</th>
                <th>Tarifs</th>         
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Programme mensuel</td>
                <td> 80 € /pers</td>
              </tr>
              <tr>
                <td>Programme de préparation physique mensuel</td>
                <td>150 € /pers</td>
              </tr>
              
            </tbody>
          </table>
          <button className='btn lg'><a href="mailto:sbabatoherou@gmail.com" >Contactez-moi</a></button>
          </Card>
        </article>
        <article>
        <Card  className='plan'>
          <h3>Mes Packs</h3>
          <table>
            <thead>
              <tr>
                <th>Pack de 10h</th>
                <th>Tarifs</th>         
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>10 séances d'1 heure/séance</td>
                <td>450 €</td>
              </tr>
              <tr>
                <td>20 séances de 30 min/séance</td>
                <td>500 €</td>
              </tr>
              
            </tbody>
          </table>
          <button className='btn lg'><a href="mailto:sbabatoherou@gmail.com" >Contactez-moi</a></button>
          </Card>
        </article>
       
      </div>
    </section>
    </>
  )
}

export default Plans