import {Link} from 'react-router-dom'
import Logo from '../images/logo_white.png'
import { FaFacebookF } from 'react-icons/fa'
import { AiOutlineYoutube } from 'react-icons/ai'
import { AiFillInstagram } from 'react-icons/ai'
import { AiOutlineMail } from 'react-icons/ai'
import { BsTelephone } from 'react-icons/bs'
import { FaRegAddressCard } from 'react-icons/fa'





const Footer = () => {
  return (
    <footer>
        <div className="container footer__container">
            <article>
                <Link to="/" className='logo'>
                    <img src={Logo} alt="Footer Logo" />
                </Link>
                <p>
                    Je reste joignable également sur facebook et instagram. 
                </p>
                <div className="footer__socials">
                    <a href="https://www.facebook.com/Escroo.Lescroc" target="_blank" rel='noreferrer noopener'><FaFacebookF/></a>
                    <a href="https://www.youtube.com/channel/UCHMT6yWu1uYRyLeNzUJhx2g" target="_blank" rel='noreferrer noopener'><AiOutlineYoutube/></a>
                    <a href="https://www.instagram.com/b.mass_/" target="_blank" rel='noreferrer noopener'><AiFillInstagram/></a>
                </div>
            </article>
            <article>
                <h4>Permaliens</h4>
                <Link to="/about">A propos</Link>
                <Link to="/plans">Tarifs</Link>
                <Link to="/gallery">Gallery</Link>
                <Link to="/contact">Contact</Link>
            </article>
            
            <article>
                <h4>Contact</h4>
                <Link to="/contact">Contactez-moi</Link>
                <div className='footer__contact'>
               
                <FaRegAddressCard className='footer-contact-icon'/>
                <p>17 rue Alphone karr - 75019 PARIS</p>
                </div>
                <div className='footer__contact'>
                <AiOutlineMail className='footer-contact-icon'/>  
                <p>massaoud.baba@hotmail.fr</p>
                </div>
               <div className='footer__contact'>
               <BsTelephone className='footer-contact-icon'/>
               <p>+33 6 95 39 32 18</p>
               </div>
            </article>
        </div>
        <div className="footer__copyright">
            <small>2023 FIT-MASS-GRAVITY &copy; All Rights Reserved</small>
        </div>
    </footer>
  )
}

export default Footer