import {SiOpenaigym} from 'react-icons/si'



export const links = [
    {
        name: "Accueil",
        path: '/'
    },
    {
        name: "A Propos",
        path: '/about'
    },
    {
        name: "Galerie-Photo",
        path: '/gallery'
    },
    {
        name: "Tarifs",
        path: '/plans'
    },
    {
        name: "Contact",
        path: '/contact'
    }
]







export const programs = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "SÉANCES AVEC ABONNEMENT",
        info: "Effectuée privée en salle de sport en adéquation avec les objectifs fixés. Engagement minimum de 3 mois , ce qui offre une motivation conséquente pour continuer ",
        path: "/plans"
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "SÉANCES SANS ABONNEMENT",
        info: " Tu es de passage et tu veux maintenir ta routin d’entrainement avec un personal trainer ?cette formule est idéale pour toi Tu veux connaitre l’utilisation des machines ",
        path: "/plans"
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "PROGRAMME ET PLANIFICATION",
        info: " Celle-ci est pratique pour des personnes qui veulent combiner les séances et Programme de préparation physique mensuel",
        path: "/plans"
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "PACKS",
        info: "Les packs sont idéales pour les personnes qui veulent faire de bonne affaires car les séances vous reviennent moins chers",
        path: "/plans"
    }
]








export const values = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "Plus-Value N°1",
        desc: "Mon expertise pour vous garantir un encadrement optimal."
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "Plus-Value N°2",
        desc: "Vaincre votre difficulté"
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "Plus-Value N°3",
        desc: "Dominer votre peur."
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "Plus-Value N°4",
        desc: "Triompher de la fatigue."
    }
]









export const faqs = [
    {
        id: 1,
        question: "À quelle fréquence dois-je faire de l'exercice ?",
        answer: "Il est primordial de faire 2 entrainements par semaine"
    },
    {
        id: 2,
        question: "À quel moment de la journée est-il préférable de s'entraîner ?",
        answer: "Il n'y a pas de moment préférable. Un entrainement doit être fait en fonction de sa disponibilité"
    },
    {
        id: 3,
        question: "Combien de temps doit durer mes entraînements ?",
        answer: "Un entrainement optimal ne doit pas excédé 1 heure."
    },
    {
        id: 4,
        question: "Dois-je m'échauffer avant mes entraînements ?",
        answer: "Il est conseillé de ne pas excéder les 15 minutes de cardio. Mais on peux aussi choisir de ne pas s'échauffer. C'est selon ses préférences"
    },
    {
        id: 5,
        question: "Dois-je faire de la musculation, du cardio ou les deux ?",
        answer: " Il faut être actif et avoir une dépense calorique importante. Il vaut mieux privilégier la musculation au cardio"
    },
    {
        id: 6,
        question: "Dois-je soulever des poids pour faire de la musculation ?",
        answer: "Pas nécessairement. La musculation peut se faire au poids du corps."
    }
]








export const testimonials = [
    {
        id: 1,
        name: "Solene THEODOSE ",
        quote: "Voulant passer des examens pour intégrer l'armée, et n'étant pas toute forte au niveau de mon endurance cardio vasculaire, j'ai sollicité pour 3 mois Mass afin de combler cette lacune. Aujourd'hui je me sens beaucoup plus forte et je vis mon rêve et le le dois à la bienveillance de Mass. Je le recommande les yeux fermés",
        job: "militaire",
        avatar: require("./images/avatar1.jpg")
    },
    {
        id: 2,
        name: "Louis HERENG",
        quote: "Coach au top et qui est à l'écoute. Je le recommande pour son professionnalisme sans faille",
        job: "Software Egineer",
        avatar: require("./images/avatar2.jpg")
    },
    {
        id: 3,
        name: "Assad Dom",
        quote: "Mass est une personne simple et très sérieux dans son travail de coach. Je vous le recommande vivement.",
        job: "University Lecturer",
        avatar: require("./images/avatar3.jpg")
    },
    {
        id: 4,
        name: "Amelie TRACHE",
        quote: "Bonjour. Je recommande fortement Mass étant un très bon coach très motivant et qui nous fait nous surpasser pour obtenir un très bon résultat. Il est très professionel, aime son métier et le connaît bien. Je ne suis pas deçue de ses prestations. Il sait encourager et nous progressons très bien avec lui, puis il nous donne de bon conseils. Tout est dit. Merci encore Mass pour tout ce que tu m'apportes",
        job: "Talking Parrot",
        avatar: require("./images/avatar4.jpg")
    },
    {
        id: 5,
        name: "Euphémie GROSSE",
        quote: "Mass est quelqu'un de passionné par son métier. Il a su s'adapter à mon profil de part mon incapacité à faire des torsions au niveau du rachis à cause de mon accident. Je ne regrette pas de l'avoir eu comme accompagnant dans mes objectifs de forme.",
        job: "Pharmacist",
        avatar: require("./images/avatar5.jpg")
    }
]















