import {Link} from 'react-router-dom'
import Image from '../images/main_header.jpeg'


const MainHeader = () => {
  return (
    <header className="main__header">
      <div className="container main__header-container">
        <div className="main__header-left">
          <h4># 12 Semaines pour Atteindre Votre Objectif </h4>
          
          <h1>Fit-Mass & Gravity </h1>
          <p> Enthousiaste, débordant d'énergie et professionel, j'apporte toute mon expertise pour vous garantir un encadrement optimal
            Vaincre une difficulté, dominer une peur, triompher de la fatigue. Voilà ce que mes coachings vous apporteront au quotidien. 
          </p>
          
          <Link to="/contact" className='btn'>Reservez votre coaching</Link>
          
        </div>
        <div className="main__header-right">
          
          <div className="main__header-image">
            <img src={Image} alt="Main Header Image" />
          </div>
        </div>
      </div>
    </header>
  )
}

export default MainHeader